import {createContext, useEffect, useState} from 'react';
const CartContext = createContext({})

export function CartWrapper(props) {
  const [items, setItems] = useState([])
  const [cartKey, setCartKey] = useState()
  const [sharedCart, setSharedCart] = useState(); 
  useEffect(() => {
    if (process.env.NODE_ENV !== 'test')  {
      fetch('/lex-api/cart/show')
        .then((res) => res.json())
        .then((data) =>   {
          setItems(data.products)
          setCartKey(data.uid)
        }
        )
    }
  },[])

  const itemInCart = (productId) => {
    return  items?.map((data) => data.id).includes(productId); 
  }

  const addItem = (productId) => {
    fetch(`/lex-api/cart_items/${productId}`, {method: 'POST', body: JSON.stringify({cart_key: cartKey})})
      .then((resp) => {
        if (resp.ok) { 
          return  resp.json()
        }
        else {
          throw Error("There was a problem adding this item to the cart");
        }
      })
      .then((data) => setItems((old) => [...old, {id: productId} ]  ) )
      .catch((err) => alert(err)  )
  }

  const shareCart = () => {
    return fetch(`/lex-api/share_cart/${cartKey}`, {method: 'POST' })
      .then((resp) => {
        if (resp.ok) { 
          return  resp.json()
        }
        else {
          throw Error("There was a problem sharing this cart.");
        }
      })
      .then((data) => {
        let segments = data.share_link.split('/') 
        let id = segments[segments.length - 1]; 
        const {protocol, host} = window.location 
        data.share_link =  `${protocol}//${host}/lex-cms/shared-saved-items/${id}`;
        return data
        //setSharedCart(() => setSharedCart(data)  );
          } )
      .catch((err) => alert(err)  )
  }

  const clearCart = () => {
    return fetch(`/lex-api/cart/${cartKey}`, {method: 'DELETE' })
      .then((resp) => {
        if (resp.ok) { 
          return  resp.json()
        }
        else {
          throw Error("There was a problem clearing this cart.");
        }
      })
      .then((data) => { window.location.reload(); } )
      .catch((err) => alert(err)  )
  }

 const removeItem = (productId) => {
    fetch(`/lex-api/cart_items/${productId}`, {method: 'DELETE', body: JSON.stringify({cart_key: cartKey})})
      .then((resp) => {
        if (resp.ok) { 
          return  resp.json()
        }
        else {
          throw Error("There was a problem removing this item from the cart");
        }
      })
      .then((data) => setItems((old) => old.filter((data) => data.id !== productId )  ) )
      .catch((err) => alert(err)  )
  }

  return (
    <CartContext.Provider value={{items: items, addItem: addItem, 
      removeItem: removeItem, itemInCart: itemInCart, 
      shareCart: shareCart, sharedCart: sharedCart, clearCart: clearCart} } > 
      {props.children}
    </CartContext.Provider>
  )
}
export { CartContext };

